import React, {useState} from 'react';
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import {lang} from "../../helpers/lang/lang";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import Button from "../../components/Button/Button";
import {Link} from "react-router-dom";
import {conn} from "../../helpers/request/connect";
import {cookies} from "../../helpers";
import {getRandomToken} from "../../helpers/users";

const Login = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const token = getRandomToken(64);

    return (
        <Div column alignH={"center"}>
            <Space size={"sm"} vertical={true}/>
            <Text size={"md"} weight={"medium"} align={"center"}>Sign in</Text>
            <Space size={"xmd"} vertical={true}/>
            <Input
            label={"Email"}
            placeholder={"Email"}
            type={"email"}
            onChange={(e)=>setData(prev=>{return{...prev, email: e}})}
            />
            <Space size={"md"} vertical={true}/>
            <Input
            label={"Password"}
            placeholder={"Password"}
            type={"password"}
            onChange={(e)=>setData(prev=>{return{...prev, password: e}})}
            />
            <Space size={"md"} vertical={true}/>
            <Button loading={loading} stretch={"fixed"} title={"Sign in"} onClick={()=>{
                setLoading(true);
                conn("auth/login", {...data, token: token, os: "web"}).then((res: any)=>{
                    if(res.response && res.response.id && res.response.token){
                            cookies.set("tp_id", res.response.id).then(()=>{
                                cookies.set("tp_token", res.response.token).then(()=>{
                                    window.location.href = "/";
                                });
                            });
                    }else{
                        setLoading(false);
                        alert("Incorrect email or password")
                    }
                })
            }}/>
            <Space size={"md"} vertical={true}/>
            <Link to={"/register"}>
                <Text>Registration</Text>
            </Link>
            <Space size={"sm"} vertical={true}/>
            <Link to={"/register"}>
                <Text color={"grey-light"}>Reset password</Text>
            </Link>
        </Div>
    );
};

export default Login;
