import React, {useEffect, useRef, useState} from 'react';
import Div from "../../components/Div/Div";
let localStream = null;
const VideoCall = ({roomId}) => {
    // const [localStream, setLocalStream] = useState(null);
    const [peerConnection, setPeerConnection] = useState(null);
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const socketRef = useRef(null);
    const  buttonRef = useRef(null);
    const remoteStream = useRef(new MediaStream()); // Используем ref для удаленного потока

    const servers = {
        iceServers: [
            {
                urls: 'stun:stun.l.google.com:19302' // STUN сервер Google
            }
        ]
    };

    useEffect(() => {
        // Открытие WebSocket соединения
        socketRef.current = new WebSocket('wss://call.tport.site');

        socketRef.current.onopen = () => {
            console.log('WebSocket соединение установлено');
        };

        socketRef.current.onerror = (error) => {
            console.error('Ошибка WebSocket:', error);
        };

        socketRef.current.onclose = () => {
            console.log('WebSocket соединение закрыто');
        };

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        // Захват видео с камеры
        const startVideo = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                // setLocalStream(stream);
                localStream = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error('Error accessing media devices.', error);
            }
        };

        startVideo();
    }, []);

    const handleJoinRoom = () => {
        socketRef.current.send(JSON.stringify({ type: 'join', roomId }));
        console.log(`Отправлено сообщение о присоединении к комнате: ${roomId}`);

        const pc = new RTCPeerConnection(servers);
        setPeerConnection(pc);

        localStream.getTracks().forEach(track => pc.addTrack(track, localStream));

        pc.ontrack = (event) => {
            // Добавляем трек в удаленный поток
            remoteStream.current.addTrack(event.track);
            remoteVideoRef.current.srcObject = remoteStream.current; // Обновляем srcObject
        };

        pc.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, roomId }));
            }
        };

        socketRef.current.onmessage = async (message) => {
            const data = JSON.parse(message.data);

            if (data.type === 'offer') {
                await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
                const answer = await pc.createAnswer();
                await pc.setLocalDescription(answer);
                socketRef.current.send(JSON.stringify({ type: 'answer', answer, roomId }));
            } else if (data.type === 'answer') {
                await pc.setRemoteDescription(new RTCSessionDescription(data.answer));
            } else if (data.type === 'candidate') {
                if (data.candidate) {
                    await pc.addIceCandidate(new RTCIceCandidate(data.candidate));
                }
            }
        };

        pc.createOffer().then(offer => {
            pc.setLocalDescription(offer);
            socketRef.current.send(JSON.stringify({ type: 'offer', offer, roomId }));
        });
    };

    return (
        <div>
            <video ref={localVideoRef} autoPlay muted style={{ width: '45%', margin: '10px', border: '2px solid green' }}></video>
            <video ref={remoteVideoRef} autoPlay style={{ width: '45%', margin: '10px', border: '2px solid red' }}></video>
            <br />
            <button ref={buttonRef} onClick={handleJoinRoom}>Подключиться</button>
        </div>
    );
};

export default VideoCall;
