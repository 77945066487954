import React, {useEffect, useRef, useState} from 'react';
import {Portal} from "react-portal";
import Div from "../../components/Div/Div";
import "./CallItem.scss";
import {useDispatch, useSelector} from "react-redux";
import {getCall} from "../../redux/selectors/call";
import {chatById} from "../../redux/selectors/chats";
import Avatar from "../../components/Avatar/Avatar";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import {setCall} from "../../redux/slices/call";
import {unix} from "../../helpers";
import {conn} from "../../helpers/request/connect";
import Space from "../../components/Space/Space";
import VideoCall from "./VideoCall";
const audioCalling = require("../../assets/audio/phone-calling.mp3");
const audioRinging = require("../../assets/audio/ringing.mp3");
const audioBusy = require("../../assets/audio/busy.mp3");

const CallItem = () => {
    const call = useSelector(getCall());
    const chat = useSelector(state=>chatById(state, call?.chat_id));
    const dispatch = useDispatch();

    const [timer, setTimer] = useState("00:00");
    const [timerTimeOut, setTimerTimeOut] = useState(null);
    const [noAnswerTimer, setNoAnswerTimer] = useState<any>(null);

    const [localStream, setLocalStream] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [peerConnection, setPeerConnection] = useState(null);
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const socketRef = useRef(null);



  /*  const servers = {
        iceServers: [
            {
                urls: 'stun:stun.l.google.com:19302' // STUN сервер Google
            }
        ]
    };

    useEffect(()=>{
        console.log("call", call);
    }, [call]);
 
    useEffect(() => {
        // Захват видео с камеры
        let stream;

        if(call.started || call.call_type === "outcome"){
          if(socketRef?.current) handleJoinRoom(call.chat_id);
            const startMedia = async () => {
                try {
                    stream = await navigator.mediaDevices.getUserMedia({ video: call.is_video, audio: true });
                    setLocalStream(stream);
                    if (localVideoRef.current) {
                        localVideoRef.current.srcObject = stream;
                    }
                } catch (error) {
                    console.error('Error accessing media devices.', error);
                }
            };

            startMedia().then();
        }

        return () => {
            stream?.getTracks()?.forEach(function(track) {
                track.stop();
            });
        };

    }, []);


    useEffect(() => {
        // Захват видео с камеры
        let stream;

        if(call.started && call.call_type === "income"){

            const startMedia = async () => {
                try {
                    stream = await navigator.mediaDevices.getUserMedia({ video: call.is_video, audio: true });
                    setLocalStream(stream);
                    if (localVideoRef.current) {
                        localVideoRef.current.srcObject = stream;
                    }
                } catch (error) {
                    console.error('Error accessing media devices.', error);
                }
            };

            startMedia().then(()=>{
                if(socketRef?.current) {
                    setTimeout(()=> handleJoinRoom(call.chat_id), 2000);
                }
            });
        }

        return () => {
            stream?.getTracks()?.forEach(function(track) {
                track.stop();
            });
        };

    }, [call, socketRef]);
*/
/*


    useEffect(()=>{
        if(call?.call_type === "outcome" && !call?.started) setNoAnswerTimer(setTimeout(()=>{
            conn("calls/noAnswer", {id: call.id});
        }, 1000 * 32));

        return ()=>{
            clearTimeout(noAnswerTimer);
        }
    }, [call]);

    useEffect(()=>{
     if(call?.started){
         setTimerTimeOut(setInterval(()=>{
            setTimer(formatTimeMinutes(unix(new Date().getTime()) - call.started));
         }, 1000));
     }
        return ()=>clearInterval(timerTimeOut);
    }, [call?.started]);*/

   /* useEffect(() => {
        // Открытие WebSocket соединения
        socketRef.current = new WebSocket('wss://call.tport.site');

        socketRef.current.onopen = () => {
            console.log('WebSocket соединение установлено');
        };

        socketRef.current.onerror = (error) => {
            console.error('Ошибка WebSocket:', error);
        };

        socketRef.current.onclose = () => {
            console.log('WebSocket соединение закрыто');
        };

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, []);


    const handleJoinRoom = (roomId) => {
        const pc = new RTCPeerConnection(servers);
        setPeerConnection(pc);

        // Отправляем сообщение о присоединении к комнате
        socketRef.current.send(JSON.stringify({ type: 'join', roomId }));
        console.log(`Отправлено сообщение о присоединении к комнате: ${roomId}`);

        // Добавляем локальные треки к PeerConnection
        localStream?.getTracks().forEach(track => pc.addTrack(track, localStream));

        // Обработка удаленных треков
        const newRemoteStream = new MediaStream();  // Создаем новый MediaStream для удаленного видео
        setRemoteStream(newRemoteStream);


        pc.ontrack = (event) => {
            console.log('Получен трек от удаленного пользователя', event.track);
            newRemoteStream.addTrack(event.track);  // Добавляем трек в удаленный поток
            if (remoteVideoRef.current) {
                console.log("to remote");
                remoteVideoRef.current.srcObject = newRemoteStream;
            }
        };



        // ICE кандидаты
        pc.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, roomId }));
            }
        };

        // Обработка сообщений от сокета
        let iceCandidateBuffer = [];

// Добавляем кандидата в буфер или сразу применяем
        function handleNewICECandidate(candidate) {
            if (peerConnection.remoteDescription) {
                peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
                    .catch(error => console.error('Ошибка при добавлении ICE-кандидата:', error));
            } else {
                iceCandidateBuffer.push(candidate);
            }
        }

// Устанавливаем удаленное описание и применяем кандидатов
        async function handleRemoteDescription(description) {
            await peerConnection.setRemoteDescription(new RTCSessionDescription(description));

            // Добавляем все кандидаты из буфера
            for (let candidate of iceCandidateBuffer) {
                await peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
                    .catch(error => console.error('Ошибка при добавлении ICE-кандидата из буфера:', error));
            }

            // Очищаем буфер
            iceCandidateBuffer = [];
        }

// Обработка сообщений от WebSocket
        socketRef.current.onmessage = async (message) => {
            const data = JSON.parse(message.data);

            // Проверка инициализации peerConnection
            if (!peerConnection) {
                console.error("peerConnection не инициализирован.");
                return;
            }

            // Проверка на наличие предложения (offer)
            if (data.type === 'offer') {
                // Проверка на удаленное описание
                if (peerConnection.remoteDescription) {
                    console.log("Удаленное описание уже установлено. Игнорируем новое предложение.");
                    return;
                }

                // Устанавливаем удаленное описание (offer)
                await peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));

                // Создаем ответ (answer)
                const answer = await peerConnection.createAnswer();
                await peerConnection.setLocalDescription(answer);

                // Отправляем ответ обратно
                socketRef.current.send(JSON.stringify({ type: 'answer', answer, roomId }));
            }

            // Обработка ответа (answer)
            else if (data.type === 'answer') {
                if (!peerConnection.remoteDescription) {
                    await peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
                } else {
                    console.log("Удаленное описание уже установлено. Игнорируем новый ответ.");
                }
            }

            // Обработка ICE-кандидатов
            else if (data.type === 'candidate') {
                handleNewICECandidate(data.candidate);
            }
        };


        // Создание offer и отправка на сервер
        pc.createOffer().then(offer => {
            pc.setLocalDescription(offer);
            socketRef.current.send(JSON.stringify({ type: 'offer', offer, roomId }));
        });
    };

*/
    const UserAvatar = ()=>{
        return <Div className={"call-audio"} column alignH={"center"}>
            <Div column alignH={"center"}>
                <Avatar size={"lg"} is_user={true}
                        src={chat?.image ? "https://api.tport.site/files/" + chat.image : ""}/>
                <Space size={"sm"} vertical/>
                <Text color={"light"}>{chat.title}</Text>
            </Div>
        </Div>
    }

    return (
        <Portal node={document && document.getElementById('call-content')}>
            <Div className={"call-content"} column>
                <VideoCall roomId={call.chat_id}/>
               {/* <Div className={"call-content-in"}>
                    {call.started? <>
                        {call.is_video? <>
                            <video id="localVideo" ref={localVideoRef} autoPlay muted style={{ width: '45%', margin: '10px', border: '2px solid green' }}></video>
                            <video id="remoteVideo" ref={remoteVideoRef} autoPlay style={{ width: '45%', margin: '10px', border: '2px solid red' }}></video>
                        </>: <>
                        <UserAvatar/>
                        </>}
                    </>: <>
                        {call.is_video? <>
                            {call.call_type === "outcome"? <video id="localVideo" ref={localVideoRef} autoPlay muted style={{ width: '45%', margin: '10px', border: '2px solid green' }}></video>: <UserAvatar/>}

                        </>: <>
                            <UserAvatar/>
                        </>}
                    </>}

                </Div>
                <Div className={"call-status"} alignV={"center"}>
                    <Text color={"light"}>
                        {call.started? timer: (call.call_type === "outcome"? "Outgoing call": (call.call_type === "busy"? "User is busy":"Incoming Call"))}
                    </Text>

                </Div>
                <Div className={"call-footer"} alignV={"center"} gap={20}>
                    {call.started? <>
                        <Button stretch={"fixed"} title={"End call"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/endCall", {id: call?.id}).then();
                        }}/>
                    </>: (call.call_type === "outcome"? <><Button stretch={"fixed"} title={"End call"} color={"red"} onClick={() => {
                        dispatch(setCall(false));
                        conn("calls/rejectCall", {id: call?.id}).then();
                    }}/>
                    <CallAudio src={audioCalling} infinity={true}/>
                    </>: (call.call_type === "income"? <>
                        <CallAudio src={audioRinging}/>
                        <Button stretch={"fixed"} title={"Reject"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/rejectCall", {id: call?.id}).then();
                        }}/>
                        <Button stretch={"fixed"} title={"Accept"} color={"green"} onClick={()=> {
                            dispatch(setCall({...call, started: unix()}));
                            conn("calls/acceptCall", {id: call.id}).then();
                        }}/>
                    </>: (call.call_type === "busy"? <>
                        <Button stretch={"fixed"} title={"Repeat call"} color={"green"} onClick={()=> {
                            dispatch(setCall({
                                chat_id: chat.id,
                                call_type: "outcome",
                            }));
                            conn("calls/callUser", {chat_id: chat?.id}).then((res:any)=>{
                                if(res.error === "busy"){
                                    dispatch(setCall({
                                        chat_id: chat?.id,
                                        type: "call",
                                        call_type: "busy"
                                    }));
                                }
                            });
                        }}/>
                        <Button stretch={"fixed"} title={"Cancel"} color={"red"} onClick={()=>dispatch(setCall(false))}/>
                        <CallAudio src={audioBusy} infinity={false}/>
                    </>: null)))}
                </Div>*/}
            </Div>


           {/* <Div className={"call-dialog"} alignH={"center"} width={"none"}>
                {chat? <Div alignH={"center"} gap={6} className={"call-chat"}>
                    <Avatar is_user={true} src={chat?.image? "https://api.tport.site/files" + chat.image : ""}/>
                    <Text color={"light"} weight={"bold"}>{chat?.title}</Text>
                </Div>: null}
                <Div className={"call-status"} alignH={"center"}>
                    {call?.call_type === "outcome" && <Div alignH={"center"} gap={6}>
                        <Text color={"light"}>Calling...</Text>
                        <Button stretch={"fixed"} title={"End"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/endCall", {id: call?.id}).then();
                        }}/>
                        <CallAudio src={audioCalling} infinity={true}/>
                    </Div> || call?.started && <Div alignH={"center"} gap={6}>
                        <Text color={"light"}>{timer}</Text>
                        <Button stretch={"fixed"} title={"End"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/endCall", {id: call?.id, time: unix() - call?.started}).then();
                        }}/>
                    </Div> || call.call_type === "income" && <Div alignH={"center"} gap={6}>
                        <Text color={"light"}>Incoming</Text>
                        <Button stretch={"fixed"} title={"Accept"} color={"green"} onClick={()=> {
                            dispatch(setCall({...call, started: unix()}));
                            conn("calls/acceptCall", {id: call.id}).then();
                        }}/>
                        <Button stretch={"fixed"} title={"Reject"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/rejectCall", {id: call?.id}).then();
                        }}/>
                        <CallAudio src={audioRinging} infinity={true}/>
                    </Div> || call.call_type === "busy" && <Div gap={6} alignH={"center"}>
                        <Text singleLine color={"light"}>User is busy</Text>
                        <Button stretch={"fixed"} title={"Repeat call"} color={"green"} onClick={()=> {
                            dispatch(setCall({
                                chat_id: chat.id,
                                call_type: "outcome",
                            }));
                            conn("calls/callUser", {chat_id: chat?.id}).then((res:any)=>{
                                if(res.error === "busy"){
                                    dispatch(setCall({
                                        chat_id: chat?.id,
                                        type: "call",
                                        call_type: "busy"
                                    }));
                                }
                            });
                        }}/>
                        <Button stretch={"fixed"} title={"Cancel"} color={"red"} onClick={()=>dispatch(setCall(false))}/>
                        <CallAudio src={audioBusy} infinity={false}/>
                    </Div> || <></>}
                </Div>
            </Div>*/}
        </Portal>
    );
};

export default CallItem;

export const CallAudio = (props)=>{
    const {src, infinity} = props;


    const audioRef = useRef();

    useEffect(() => {
        const audioElement: any = audioRef.current;
        const handleEnded = () => {
            audioElement.currentTime = 0;
            audioElement.play();
        };

      if(infinity) audioElement.addEventListener('ended', handleEnded);

        return () => {
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [infinity]);

    return <audio ref={audioRef} autoPlay={true} src={src}/>;
}
