import Store from "../redux/stores/store";
import {editMessage, removeMessage, setMessage} from "../redux/slices/messages";
import {conn} from "./request/connect";
import {chatsSetOne, chatsUpdateOne} from "../redux/slices/chats";
import {setProfile} from "../redux/slices/profile";
import {setCall} from "../redux/slices/call";
import {unix} from "./index";
import {storiesRemoveOne, storiesSetOne} from "../redux/slices/stories";

export const socketListener = (socketData) => {

    try {
        const data = JSON.parse(socketData);
        console.log("socket", data);

        switch (data.event) {
            case "new_message":
                conn("message/getMessageId", {id: data.id}).then((res: any) => {
                    if (data.tmp_id) Store.dispatch(removeMessage(data.tmp_id));
                    const message = res.response;
                    Store.dispatch(setMessage(message));
                    if(Store.getState().chats?.entities?.[message.chat_id]){
                        Store.dispatch(chatsUpdateOne({id: message.chat_id, changes: {count: message.unread_messages}}));
                    }else{
                        conn("chat/getId", {id: message.chat_id}).then((res: any) => {
                            Store.dispatch(chatsSetOne(res.response));
                        });
                    }


                });

                break;
            case "message_edit":
                conn("message/getMessageId", {id: data.id}).then((res: any) => {
                    const message = res.response;
                    Store.dispatch(setMessage(message));
                });
                break;
            case "message_liked":
                conn("message/getMessageId", {id: data.id}).then((res: any) => {
                    const message = res.response;
                    Store.dispatch(setMessage(message));
                });
                break;
            case "message_disliked":
                conn("message/getMessageId", {id: data.id}).then((res: any) => {
                    const message = res.response;
                    Store.dispatch(setMessage(message));
                });
                break;
            case "message_remove":
                Store.dispatch(removeMessage(data.id));
                Store.dispatch(chatsUpdateOne({id: data.chat_id, changes: {count: data.count}}));
                break;
            case "read_message":
                Store.dispatch(editMessage({id: data.id, changes: {is_read: true}}));
                Store.dispatch(chatsUpdateOne({id: data.chat_id, changes: {count: data.count}}));
                break;
            case "chat_new":
                conn("chat/getId", {id: data.id}).then((res: any) => {
                    Store.dispatch(chatsSetOne(res.response));
                });
                break;
            case "edit_chat":
                conn("chat/getId", {id: data.id}).then((res: any) => {
                    Store.dispatch(chatsSetOne(res.response));
                });
                break;
            case "user_unsubscribe":
                const chat = Store.getState().chats.entities[data.chat_id];
                if (chat) {
                    let users = [];
                    if (chat.is_private && Number(data.user_id) === Number(Store.getState()?.profile?.id)) {
                        users = [];
                    } else {
                        users = chat?.users?.filter(x => Number(x.id) !== Number(data.user_id))
                    }

                    Store.dispatch(chatsUpdateOne({
                        id: data.chat_id,
                        changes: {
                            users: users
                        }
                    }))

                }
                break;
            case "profile_edit":
                conn("auth/checkAuth").then((res: any) => {
                    if (res.response) {
                        Store.dispatch(setProfile(res.profile));
                    }
                });
                break;
            case "call_start":
                const call = Store.getState()?.call;
                console.log("ssss", call);
                if (Number(call?.id) === Number(data.id)) {
                    Store.dispatch(setCall({
                        chat_id: data?.chat_id,
                        id: data?.id,
                        call_type: call.call_type,
                        started: unix(),
                        is_video: data.is_video,
                    }));
                }
                break;
            case "call_income":
                Store.dispatch(setCall({
                    chat_id: data?.chat_id,
                    id: data?.id,
                    call_type: "income",
                    is_video: data.is_video,
                }));
                break;
            case "call_reject":
                Store.dispatch(setCall(false));
                break;
            case "call_end":
                Store.dispatch(setCall(false));
                break;
            case "new_comment":
                const message = Store.getState().messages?.entities?.[data.message_id];
                if(message){
                    Store.dispatch(editMessage({
                        id: data.message_id,
                        changes: {
                            comments_count: data.count
                        }
                    }));
                    conn("comments/getComments", {message_id: data.message_id}).then((res:any)=>{
                        Store.dispatch(editMessage({
                            id: data.message_id,
                            changes: {
                                comments: res.response
                            }
                        }));
                    });
                }
                break;
            case "new_story":
                conn("stories/getStory", {id: data.story_id}).then((res:any)=>{
                  if(res.response) Store.dispatch(storiesSetOne(res.response));
                });
                break;
            case "delete_story":
                Store.dispatch(storiesRemoveOne(data.story_id));
                break;
        }

    } catch (e) {
        console.log("socket error", e);
    }
}
