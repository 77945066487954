import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import Layout from "../components/Layout/Layout";
import Div from "../components/Div/Div";
import Header from "../Items/Header/Header";
import Space from "../components/Space/Space";
import Text from "../components/Text/Text";

import {checkAuth, cookies, myId} from "../helpers";
import {conn} from "../helpers/request/connect";
import {useDispatch, useSelector} from "react-redux";

import Login from "./Auth/Login";
import Register from "./Auth/Register";
import {chatsSetAll, chatsSetMany, chatsSetOne} from "../redux/slices/chats";
import Chats from "../components/Chats/Chats";
import {setManyMessages} from "../redux/slices/messages";
import ChatsContent from "../components/Chats/ChatsContent";
import {setProfile} from "../redux/slices/profile";
import {socketListener} from "../helpers/socket";
import {getProfile} from "../redux/selectors/profile";
import Button from "../components/Button/Button";
import {FiPlus} from "react-icons/fi";
import ContextMenu from "../components/ContextMenu/ContextMenu";
import ListItem from "../components/ListItem/ListItem";
import Dialog from "../components/Dialog/Dialog";
import Input from "../components/Input/Input";
import UserPage from "./UserPage/UserPage";
import ChannelPage from "./UserPage/ChannelPage";
import {usersSetMany} from "../redux/slices/users";
import "./styles.scss";
import {getCall} from "../redux/selectors/call";
import CallItem from "../Items/CallItem/CallItem";
import Profile from "./Profile/Profile";
import StoriesContent from "../Items/StoriesContent/StoriesContent";



const Main = () => {
    const params = useParams();
    const [loading, setLoading] = useState(!params.p1);
    const profile = useSelector(getProfile());
    const [context, setContext] = useState(null);
    const [popup, setPopup] = useState(null);
    const [info, setInfo] = useState<any>("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const call = useSelector(getCall());

    const ws = useWebSocket(`wss://socket.tport.site`, profile?.id);


    useEffect(()=>{
        // @ts-ignore
        if(checkAuth()){
            conn("auth/checkAuth").then((res:any)=>{
                if(res.response){
                    dispatch(setProfile(res.profile));
                    // const client = new W3CWebSocket(`wss://api.tport.site/socket?id=${cookies.get("tp_id")}`);
                    setLoading(false);
                   /* client.onopen = () => {
                        console.log('WebSocket Client Connected');
                    };
                    client.onmessage = (message) => {
                       socketListener(message.data);
                    };*/
                conn("chat/getAll").then((res: any)=>{
                    if(res.response){
                        dispatch(setManyMessages(res.response.map(x=>x.message?? {})));
                        dispatch(chatsSetMany(res.response));
                        dispatch(usersSetMany(res.response?.flatMap(chat => chat.users)));
                    }
                    setLoading(false);
                });
                }else{
                    cookies.set("tp_id", "", -5).then(()=> {
                        cookies.set("tp_token", "", -5).then(() => {
                            window.location.href = "/login"
                        });
                    });
                }
            });
        }else if(params.p1 !== "login"){
             window.location.href = "/login"
        }

    }, []);

    return (<Div column={true} alignH={"center"} className={"main-content"}>
            {loading ? <>Loading...</> : <>
                <Header/>
                <Space vertical={true} size={"sm"}/>
                {params.p1 === "login" || params.p1 === "register" ? <>
                    <Layout>
                        {params.p1 === "register" && <Register/> || <Login/>}
                    </Layout>
                </>: <>
                    <Layout
                        left={<Div column={true} overflowHidden={true}>
                            <Space vertical={true} size={"sm"}/>
                            <StoriesContent/>
                            <Space vertical={true} size={"sm"}/>
                            <Div alignH={"center"} alignV={"between"}>
                                <Text padding={"sm"} weight={"bold"}>Chats</Text>
                            <Button onClick={(e)=>setContext(e.target.closest(".button"))} size={"xsm"} color={"light"} iconLeft={<FiPlus/>}/>
                            </Div>
                            <Space size={"xsm"} vertical={true}/>
                            <Chats/>
                        </Div>}
                    >
                        {params.p1 === "login" && <Login/>
                            || params.p1 === "user" && <UserPage/>
                            || params.p1 === "channel" && <ChannelPage/>
                            || params.p1 === "profile" && <Profile/>
                            || <ChatsContent/>}
                    </Layout>
                </>}


                {context && <ContextMenu alignTo={context} onClose={()=>setContext(null)}
                content={[
                    <ListItem hover title={"Create Group"} onClick={()=> {
                        setPopup(1);
                        setContext(null);
                    }}/>,
                    <ListItem hover title={"Create Channel"} onClick={()=> {
                        setPopup(2);
                        setContext(null);
                    }}/>,
                ]}
                /> }

                {popup && <Dialog
                    size={"xsm"}
                    title={"Create chat"}
                    buttons={{right: <Div gap={6}>
                            <Button title={"cancel"} onClick={()=> {
                                setPopup(null);
                                setInfo(null);
                            }} color={"transparent"}/>
                            <Button disabled={!info?.title?.length} title={"Create"} onClick={()=> {
                                conn("chat/create", {type: popup === 1? "group": "channel", title: info.title}).then((res:any)=>{
                                    if(res.response){
                                        dispatch(chatsSetOne(res.response));
                                        navigate("/chats/"+ res.response.id);
                                        document.body.classList.remove("menu-opened");
                                    }
                                    setPopup(null);
                                    setInfo(null);
                                });
                            }} color={"main"}/>
                        </Div>}}
                    onClose={()=> {
                        setPopup(null);
                        setInfo(null);
                    }}
                >
                    <Text>New {popup === 1? "Group": "Channel"}</Text>
                    <Space size={"sm"} vertical/>
                    <Input placeholder={"Title"} stretch value={info?.title ?? ""} onChange={(e)=>setInfo(prev=>{return{...prev, title: e}})}/>
                </Dialog>}

            <Space vertical={true} size={"sm"}/>
            </>}

            {call && <CallItem/> || null}
        </Div>
    );
};

export default Main;


const useWebSocket = (url, id) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        let ws;
        const connect = () => {
            if(url && id){
                ws = new WebSocket(`${url}?id=${id}`);
                setSocket(ws);

                ws.onopen = () => {
                    console.log('WebSocket connected');
                };

                ws.onclose = (e) => {
                    console.log('WebSocket connection closed, reconnecting...', e);
                    setTimeout(connect, 1000); // Попробуем переподключиться через 1 секунду
                };

                ws.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                ws.onmessage = (message) => {
                    socketListener(message.data);
                };
            }

        };

        connect();

        return () => {
            ws?.close();
        };
    }, [id]);

    return socket;
};

